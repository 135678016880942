@import "styles/core.scss";
.container {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.swiperContainer {
  width: 100%;
}

.swiperContainer {
  position: relative;
  max-width: 100%;
  overflow: hidden;

  &.showOverflow {
    overflow: visible;
    .swiper {
      overflow: visible;
    }

    @include page-width-xl {
      overflow: hidden;
      .swiper {
        overflow: hidden;
      }
    }
  }
}

.swiper {
  height: 100%;
  width: 100%;
  padding-top: 6px; // For focused keyboard elements to show border
  padding-bottom: 6px; // For focused keyboard elements to show border
}

.swiper-wrapper {
  transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}

.swiper {
  &.autoSize :global(.swiper-slide) {
    width: auto;
  }

  :global(.swiper-slide) {
    &:focus-within {
      :focus-visible {
        box-shadow: 0 0 0 6px var(--color-border-focus-default);
        border-radius: var(--radius-md);
        outline: 3px solid white;
      }
    }
  }

  :global(.swiper-wrapper) {
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  }
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.leftArrow.leftArrow,
.rightArrow.rightArrow {
  position: absolute;
  z-index: 1;
  border-radius: var(--size-button-radius-md);
  cursor: pointer;
  background: var(--color-background-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-shadow: var(--shadow-200);
  height: 48px;
  width: 48px;
  top: calc(50% - 24px);
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.33, 1, 0.68, 1);

  &.adjustArrowsForPagination {
    top: calc(50% - 24px - 20px);
  }
}

.leftArrow {
  left: calc(-1 * var(--space-300));
}

.rightArrow {
  right: calc(-1 * var(--space-300));
}

// Design request: Show arrows on hover, but not on touch screens
@media (hover: hover) {
  .container:hover {
    .leftArrow,
    .leftArrow,
    .rightArrow,
    .rightArrow {
      opacity: 1;
    }
  }
}

.showArrows {
  .leftArrow,
  .leftArrow,
  .rightArrow,
  .rightArrow {
    opacity: 1;
  }
}

.defaultLoadingItem {
  width: 100%;
  height: 200px;
  background-color: var(--color-background-secondary);
  border-radius: var(--radius-md);
}

.absoluteSizeLoadingItem {
  width: 240px;
}

.aspectRatioContainer {
  width: 100%;
  position: relative;
}

.aspectRatioInner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.swiperSlide {
  margin-right: var(--space-200);

  @include page-width-xs {
    margin-right: var(--space-100);
  }
}

.swiperSlideMobile1x5 {
  width: calc((100% / 1.5) - 10px);
}

.swiperSlideMobile2 {
  width: calc((100% / 2) - 10px);
}

.swiperSlide541x2 {
  @include minWidth(541px) {
    width: calc((100% / 2) - 11px);
  }
}

.swiperSlide541x3 {
  @include minWidth(541px) {
    width: calc((100% / 3) - 11px);
  }
}

.swiperSlide760x2 {
  @include minWidth(760px) {
    width: calc((100% / 2) - 11px);
  }
}

.swiperSlide760x3 {
  @include minWidth(760px) {
    width: calc((100% / 3) - 11px);
  }
}

.swiperSlide760x4 {
  @include minWidth(760px) {
    width: calc((100% / 4) - 11px);
  }
}

.swiperSlide1121x3 {
  @include minWidth(1121px) {
    width: calc((100% / 3) - 11px);
  }
}

.swiperSlide1121x4 {
  @include minWidth(1121px) {
    width: calc((100% / 4) - 11px);
  }
}

.swiperSlide1121x5 {
  @include minWidth(1121px) {
    width: calc((100% / 5) - 11px);
  }
}
