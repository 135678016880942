@import "styles/core.scss";
@import '../layouts/layoutStyles.module.scss';

.downloadAppModuleSSection {
  @include page-width-lg-up {
    @include flex-row;
    align-items: center;
    @include colLayout;
  }
}

.titleSection {
  @include page-width-lg-up {
    position: sticky;
    top: calc(var(--space-800) * 2);
    height: fit-content;
    @include colCount(2);
  }
}

.parks {
  @include flex-row;
  align-items: center;
  gap: var(--space-100);
  margin-bottom: var(--space-300);
  @include text-200;

  @include page-width-lg-up {
    margin-bottom: var(--space-600);
  }
}

.title {
  margin-right: var(--space-800);
  margin-bottom: var(--space-50);
}

.subtitle {
  color: var(--color-text-secondary);
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.desktopButton.desktopButton {
  display: none;

  @include page-width-lg-up {
    display: block;
  }
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.mobileButton.mobileButton {
  display: block;

  @include page-width-lg-up {
    display: none;
  }
}

.movie {
  background-color: var(--color-background-secondary);
  border-radius: var(--radius-lg);
  aspect-ratio: 16/9;
  margin-bottom: var(--space-450);

  @include page-width-lg-up {
    @include colCount(4);
    margin-bottom: 0;
  }
}

.embedFlex {
  position: relative;
  border-radius: var(--radius-lg);
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.embedContainer {
  position: relative;
  border-radius: var(--radius-lg);
  padding-bottom: 56.25%; // 16:9 aspect ratio
}

.embedVid {
  position: absolute;
  object-fit: contain;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
